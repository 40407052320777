export const checkAndUpdateCart = (currentOrder, setCart, userEmail) => {
    const existingLS = localStorage.getItem('userCart');
    let parseExistingLSComplete = existingLS ? JSON.parse(existingLS) : {};
    let parseExistingLS =
        parseExistingLSComplete?.user === userEmail ? parseExistingLSComplete?.cart : {};
    // console.log('parseExistingLS:', parseExistingLS, ' --currentOrder:', currentOrder);

    // find this uuid in the existing object
    const presentAlready = Object.keys(parseExistingLS).findIndex(existingLSItem => existingLSItem === currentOrder.uuid);
    // console.log('presentAlready index:', presentAlready, ' --item:', parseExistingLS[currentOrder.uuid]);

    if (presentAlready !== -1) {
        // console.log('updated parseExistingLS:', parseExistingLS);
        const newCount = parseInt(parseExistingLS[currentOrder.uuid].count) + parseInt(currentOrder.count);
        parseExistingLS[currentOrder.uuid] = { count: newCount };
    } else {
        parseExistingLS[currentOrder.uuid] = { count: currentOrder.count };
    }

    // localStorage.setItem('cart', JSON.stringify(parseExistingLS));
    localStorage.setItem('userCart', JSON.stringify({ user: userEmail, cart: parseExistingLS }));
    setCart(parseExistingLS);
}

export const checkAndDeleteFromCart = (updatedCart, setCart, userEmail) => {
    // console.log('parseExistingLS:', parseExistingLS);
    // localStorage.setItem('cart', JSON.stringify(updatedCart));
    localStorage.setItem('userCart', JSON.stringify({ user: userEmail, cart: updatedCart }));
    setCart(updatedCart);
}

export const numberWithCommas = (x) => {
    const roundedX = Math.round(x * 100) / 100;
    return roundedX && roundedX > 0 ? roundedX.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : roundedX;
}


export const checkUpdateArrayWithUniqueValue = (completeInventory, fieldName) => {
    let uniqueValue = [];
    completeInventory.forEach(element => {
        const existsAlready = uniqueValue.findIndex(x => x.name === element[fieldName]);
        if (existsAlready === -1) {
            const inventoryCount = completeInventory.filter(newEl => {
                return newEl[fieldName] === element[fieldName]
            });
            uniqueValue = [...uniqueValue, { name: element[fieldName], count: inventoryCount.length }];
        }
    });
    return uniqueValue;
}

export const getInventoryForBrand = (completeInventory, brand) => {
    let filteredInventory = [];
    filteredInventory = completeInventory.filter(element => element['invCompany'] === brand);
    return filteredInventory;
}

export const isLoadingGifSeenAlready = (updateSessionStorageAlso) => {
    const ssValue = sessionStorage.getItem('seenLoadingScreen');
    if (ssValue === null) {
        if (updateSessionStorageAlso === true) {
            sessionStorage.setItem('seenLoadingScreen', 'true');
        }
        return false;
    } else {
        return true
    }
}