import React from 'react';

const Terms = () => {
    return <section className='terms-section'>
        <div className="row">
            <div className="category-heading">
                <h1>Terms and conditions</h1>
                <ul>
                    <li>
                        We will do our utmost to ensure that access to the Site is consistently available and is uninterrupted and error-free. However, due to the nature of the Internet and the nature of the Site, this cannot be guaranteed.
                    </li>
                    <li>
                        No refund, exchange only - customers can only exchange items they wish to return, rather than get a refund.
                    </li>
                    <li>
                        Order is only considered confirmed once you receive a confirmation on the order placed on the website; or a verbal communique via audio call.
                    </li>
                    <li>
                        Our ETD is 72hrs after the amount reflection in accounts.
                    </li>
                    <li>
                        The prices and other charges are not fixed. Can be changed, revised according to the specific need.
                    </li>
                    <li>
                        We are not liable for any damages caused by couriers.
                    </li>
                    <li>
                        There is no delivery charges on self pick up.
                    </li>
                    <li>
                        In case if unavailability of stock your 100% advance will be refunded.
                    </li>
                    <li>
                        Real items may vary slightly as compared to the picture.
                    </li>
                </ul>
                <h2>Delivering to:</h2>
                <ul className='multi-column'>
                    <li>
                        Atlasville</li><li>
                        Bonaero Park</li><li>
                        Brentwood</li><li>
                        Northmead</li><li>
                        Ravenswood</li><li>
                        Jansen Park</li><li>
                        Westdene</li><li>
                        Benoni</li><li>
                        Allen Groove</li><li>
                        Kempton Park</li><li>
                        Pomona</li><li>
                        Isandovale</li><li>
                        Delmore</li><li>
                        Van Dyk Park</li><li>
                        Boksburg</li><li>
                        Tembisa</li><li>
                        Germiston</li><li>
                        Edenvale</li><li>
                        Sandton</li><li>
                        Midrand</li><li>
                        Centurion</li><li>
                        Pretoria</li><li>
                        Irene</li><li>
                        Midstream</li><li>
                        Brakpan</li><li>
                        Springs</li><li>
                        Alberton</li><li>
                        Roodeport</li><li>
                        Krugersdrop</li>
                </ul>
            </div>
        </div>
    </section>
}

export default Terms;