import React, { useState, useEffect, } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-tabs/style/react-tabs.css';
import { inventoryCategory } from '../../dictionary';
import { InventoryItem } from './item';

export const InventorySection = (props) => {
    const { inventoryArray, setCart, brand } = props;

    var settings = {
        dots: false,
        infinite: inventoryArray > 3,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    infinite: inventoryArray > 3,
                    dots: true
                }
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: inventoryArray > 3,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    const [inventoryArrayFinal, setInventoryArrayFinal] = useState({});
    // const [companyArrayFinal, setCompanyArrayFinal] = useState(['junk']);

    // const checkInsertCompanyInArray = (invItem, localCompanyArray) => {
    //     const foundInArray = localCompanyArray.findIndex(cArry => cArry === invItem.invCompany);
    //     if (foundInArray === -1) {
    //         return invItem.invCompany;
    //     }
    // }

    useEffect(() => {
        /* let localCompanyArray = [];
        inventoryArray.map(invCompany => {
            const returnArray = checkInsertCompanyInArray(invCompany, localCompanyArray);
            if (returnArray) {
                localCompanyArray = [...localCompanyArray, returnArray];
            }
        });
        // console.log('localCompanyArray:', localCompanyArray);
        setCompanyArrayFinal(localCompanyArray); */

        let finalArray = {};
        Object.keys(inventoryCategory).map((invCat) => {
            finalArray[invCat] = [];
            return null;
        })

        inventoryArray.forEach(invC => {
            Object.keys(inventoryCategory).forEach(icKey => {
                if (inventoryCategory[icKey] === invC.invCategory) {
                    finalArray[icKey] = [...finalArray[icKey], invC];
                }
            });
        });

        // console.log('finalArray:', finalArray);
        setInventoryArrayFinal(finalArray);
    }, [inventoryArray])

    const prettifyHeading = (heading) => {
        let updatedHeading = heading.toLowerCase().replace('_', ' ');
        updatedHeading = updatedHeading.charAt(0).toUpperCase() + updatedHeading.slice(1);
        return updatedHeading;
    }

    const capitalizeName = (currentBrandName) =>
        currentBrandName.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase());

    return <>
        {/* <TabsSections companyArrayFinal={companyArrayFinal} /> */}
        {Object.keys(inventoryArrayFinal).map((finalCategory, index1) => {
            // console.log(brand, 'finalCategory:', finalCategory, ' within:', inventoryArrayFinal[finalCategory]);
            return inventoryArrayFinal[finalCategory].length > 0 && (
                <section key={`section-${index1}`}>
                    <div className="category-heading d-none d-lg-block d-xl-block">
                        <h1>{brand ? capitalizeName(brand) : 'Other'}{prettifyHeading(`: featured ${finalCategory} & spares`)}</h1>
                    </div>
                    <div className="category-heading d-lg-none">
                        <h1>{brand}</h1>
                    </div>
                    <Slider {...settings}>
                        {inventoryArrayFinal[finalCategory].map((inv, indx2) => {
                            // console.log(indx2, 'inventoryArrayFinal[finalCategory]:', inventoryArrayFinal[finalCategory]);
                            return <div key={`inv${indx2}`}>
                                <InventoryItem index={indx2} inventoryItem={inv} setCart={setCart} />
                            </div>
                        })}
                    </Slider>
                </section>
            )
        }
        )}
    </>
}

