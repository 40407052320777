import React, { useContext } from "react";
import { InventorySection } from '../sections/inventory';
import { InventoryContext } from '../routes/home';
import { getInventoryForBrand } from "./utils";
import '../styles/ticker.scss';

export const InventoryByBrand = (props) => {
    const { brands, setCart } = props;
    const { inventory } = useContext(InventoryContext);
    // console.log('brands:', brands);

    return <section id="contentSection" className='content-section'>
        <div className='left-columns'>

            <div className="ticker-wrap">
                <div className="ticker">
                    {brands.map((brand, idx) =>
                        <div className="ticker__item" key={`ticker-${idx}`}>{brand.name.toLowerCase()}
                            <span>({brand.count} {(brand.count > 1) ? 'items' : 'item'})</span>
                        </div>
                    )}
                </div>
            </div>
            <ul>
                {brands.map((brand, indx) => {
                    const filteredInventory = getInventoryForBrand(inventory, brand.name);
                    return <li key={indx}>
                        <InventorySection inventoryArray={filteredInventory} setCart={setCart} brand={brand.name} />
                    </li>
                })}
            </ul>
        </div>
    </section >
}