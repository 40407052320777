import React, { useState } from "react";
import './styles/App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Auth0Provider } from "@auth0/auth0-react";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
// import Header from './sections/header';
// import Cart from './sections/cart';
import Home from './routes/home';
import Terms from './routes/terms';
import AuthProfile from './sections/auth';
import logo from './images/lewe-carbon-logo.png';
import ProtectedRoute from './routes/protectedRoute';
import HiddenPage from './routes/hiddenPage';

function App() {

  // TODO: revert when wifi available
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idToken, setIdToken] = useState('');

  const isNight = () => {
    var date = new Date();
    return (date.getHours() > 20 || date.getHours() < 6);
  }

  return (
    <Auth0Provider
      // domain="dev-cjov6cmb.us.auth0.com"
      // clientId="M0dZhjJLlXmG7YOl92aW5kncCxCDg1z9"
      // redirectUri={window.location.origin}
      // audience={'l1jixho7zh'}
      domain={process.env.REACT_APP_DOMAIN}
      clientId={process.env.REACT_APP_CLIENTID}
      redirectUri={window.location.origin}
      audience='l1jixho7zh'
    // audience={process.env.REACT_APP_AUDIENCE}
    >
      <div className={isNight ? "night-mode" : null} >
        {/* TODO: revert when you have wifi */}
        <nav className="navbar navbar-expand-sm navbar-container " >
          <div className="row">
            <div className="col-6 col-sm">
              <div className='logo-container'>
                <a href='/' alt='Lewe Trading T?A  Car Bon'>
                  <img src={logo} alt='Lewe Trading T/A Car Bon' />
                </a>
              </div>
            </div>
            <div className="col-6 col-sm">
              <div className="header-text-area">
                <span className="header-main-text">LEWE TRADING PTY LTD <br />
                  T/A Car-Bon<br />
                </span>
                <span className="header-sub-text">Wholesale and Retail in Car & 4x4 Accessories & Spares</span>
              </div>
            </div>
            <div className="col-md-12 login-area">
              <AuthProfile setAuthenticated={setAuthenticated} setLoading={setLoading}
                setIdToken={setIdToken} />
            </div>
          </div>
        </nav>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home
              authenticated={authenticated} loading={loading} idToken={idToken}
              setAuthenticated={setAuthenticated} setLoading={setLoading}
              setIdToken={setIdToken}
            />} />
            <Route path="/!" element={<Home
              authenticated={authenticated} loading={loading} idToken={idToken}
              setAuthenticated={setAuthenticated} setLoading={setLoading}
              setIdToken={setIdToken}
            />} />
            <Route path="/home" element={<Home
              authenticated={authenticated} loading={loading} idToken={idToken}
              setAuthenticated={setAuthenticated} setLoading={setLoading}
              setIdToken={setIdToken} />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/hidden" element={<ProtectedRoute component={HiddenPage} path="/hidden" />} />
            {/* <Route path="/hidden" element={<ProtectedRoute><HiddenPage /></ProtectedRoute>} /> */}
            {/* <Route path="/categories" element={<Header authenticated={authenticated} loading={loading} idToken={idToken} />} />
          <Route path="/cart" element={<Cart authenticated={authenticated} loading={loading} idToken={idToken} />} /> */}
          </Routes>
        </BrowserRouter>
      </div>

    </Auth0Provider>
  );
}

export default App;
