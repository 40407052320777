import React, { useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

const ProtectedRoute = ({ component: Component, path, ...rest }) => {
    const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

    useEffect(() => {
        const fn = async () => {
            await loginWithRedirect({
                appState: { targetUrl: path }
            });
        }
        if (isLoading || isAuthenticated) {
            return;
        }
        fn();
    }, [isAuthenticated, isLoading, loginWithRedirect, path]);

    return isAuthenticated ? <Component /> : null
}

export default ProtectedRoute;