import React, { useState, useEffect, createContext } from "react";
import axios from 'axios';
// import { /* mockInventory, mockInventory2, mockInventory3,  */mockInventory4 } from '../mockData/inventory';
import { useAuth0 } from "@auth0/auth0-react";
import LoadingGif from '../sections/loading';
import { CartDropdown } from '../sections/cartDropdown';
import { InventoryByBrand } from '../sections/inventorySliders';
import { checkUpdateArrayWithUniqueValue } from "../sections/utils";
import { isLoadingGifSeenAlready } from '../sections/utils';

export const InventoryContext = createContext({
    inventoryArray: [],
    setInventoryArray: () => { }
});

const Home = (props) => {
    const useAuth0Object = useAuth0();
    const { user } = useAuth0Object;
    const BROCHURE_SHOW_DURATION = isLoadingGifSeenAlready() ? 100 : 7000;

    const { authenticated, loading, idToken, setAuthenticated, setLoading, setIdToken } = props;
    const [inventory, setInventory] = useState([]);
    const existingLS = localStorage.getItem('userCart');
    let parseExistingLS = existingLS ? JSON.parse(existingLS) : {};
    let parseExistingLSCart = parseExistingLS?.user === user?.email ? parseExistingLS?.cart : null;
    const [cart, setCart] = useState(parseExistingLSCart);
    const [showcase, setShowcase] = useState(true);

    useEffect(() => {
        const newParseExistingLSCart = parseExistingLS?.user === user?.email ? parseExistingLS?.cart : null;
        setCart(newParseExistingLSCart);
    }, [user]);

    const [brands, setBrands] = useState([]);

    useEffect(() => {

        const divideAllInventoryIntoCategories = (inventoryArray) => {
            const brandResult = checkUpdateArrayWithUniqueValue(inventoryArray, 'invCompany');
            brandResult && brandResult.length > 0 && brands.length === 0 && setBrands(brandResult);
        }

        const loadExperience = async () => {

            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${idToken}`
            }

            // console.log('inside loadExperience w/ headers:', headers);

            const response = await axios
                .get('https://4ykgzztbal.execute-api.us-east-2.amazonaws.com/inventory'
                    // .get('http://localhost:3000/inventory'
                    , { headers: headers })
                .catch(err => {
                    console.error('err:', err);
                });
            if (response) {
                // console.log('response:', response);
                divideAllInventoryIntoCategories(response.data);
                setInventory(response.data);
            } else {
                setInventory(['Something wrong with the API call and/or result...']);
            }

        }
        if (!authenticated) {
            loadExperience();
        }
    }, [authenticated, idToken, brands]);

    useEffect(() => {
        setTimeout(() => {
            setShowcase(false);
        }, BROCHURE_SHOW_DURATION);
    }, []);

    return (
        <InventoryContext.Provider value={{ inventory }} >
            <div className="App">
                <CartDropdown
                    authenticated={authenticated}
                    setAuthenticated={setAuthenticated}
                    inventory={inventory}
                    setIdToken={setIdToken}
                    setLoading={setLoading}
                    cart={cart}
                    setCart={setCart}
                />
                {loading || showcase ? <LoadingGif /> :
                    <div>
                        <div className="home-description-left">
                            <p>Wholesale and retail supplier of car accessories and spares - Quality material guaranteed to be delivered on time
                            </p>
                            <ol>
                                <span>Your delivery is <span>3</span> steps away:</span>
                                <li>Inquiry</li>
                                <li>Quote</li>
                                <li>EFT</li>
                                <li>Delivery</li>
                            </ol>
                        </div>
                        <InventoryByBrand
                            brands={brands} setCart={setCart}
                        />
                    </div>
                }
            </div >
        </InventoryContext.Provider>
    );
};

export default Home;