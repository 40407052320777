import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
import 'react-tabs/style/react-tabs.css';
import { checkAndDeleteFromCart, numberWithCommas } from '../utils';

const Cart = (props) => {
    const { cart, inventoryArray, setCart } = props;
    const [totalCost, setTotalCost] = useState();
    const [orderPlaced, setOrderPlaced] = useState(false);
    const [optionalPhoneAvailable, setOptionalPhoneAvailable] = useState(false);
    const [orderPlacementMessage, setOrderPlacementMessage] = useState('');
    const [optionalMobileNumber, setOptionalMobileNumber] = useState('');
    const [idToken, setIdToken] = useState('');

    const useAuth0Object = useAuth0();
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0Object;


    const getNameForUuid = (uuid) => {
        const foundMatch = inventoryArray.find(invItem => {
            return (uuid === invItem.invUuid)
        });
        return foundMatch ? `${foundMatch?.invCompany ?? ''} ${foundMatch?.invName}` : null;
    }

    const getCostForUuid = (uuid, count) => {
        const foundMatch = inventoryArray.find(invItem => {
            return (uuid === invItem.invUuid)
        });
        const costForUuid = foundMatch ? foundMatch?.invPrice * count : 0;
        return costForUuid;
    }

    useEffect(() => {
        let calcTotal = 0;
        Object.keys(cart).forEach(item => {
            calcTotal += getCostForUuid(item, cart[item].count)
        })
        setTotalCost(calcTotal);
        setOrderPlaced(false);

        const getToken = async () => {
            const idToken = await getAccessTokenSilently();
            // console.log('TODO: Profile idToken:', idToken);
            setIdToken(idToken);
        }
        isAuthenticated && getToken();

    }, [cart, inventoryArray]);

    const deleteItem = (itemToDelete) => {
        const newCart = JSON.parse(JSON.stringify(cart));
        delete newCart[itemToDelete];
        checkAndDeleteFromCart(newCart, setCart, user?.email);
        setCart(newCart);
    }

    const placeOrder = async () => {
        const reqBody = {
            orderDetails: cart,
            userName: user.nickname,
            userEmail: user.name,
            mobileNumber: optionalMobileNumber,
        };

        const headers = {
            'Authorization': idToken
        }

        await axios
            .post(`https://4ykgzztbal.execute-api.us-east-2.amazonaws.com/placeOrder`, reqBody, { headers: headers })
            // .post(`http://localhost:3000/placeOrder`, reqBody, { headers: headers })
            .then((orderResponse) => {
                // console.log('Order response:', orderResponse);
                if (orderResponse && orderResponse?.data?.body) {
                    setOrderPlaced(true);
                    const messageId = orderResponse?.data?.body ? orderResponse?.data?.body : JSON.parse(orderResponse?.data?.body);
                    setOrderPlacementMessage(`order placed, 
                    reference: ${messageId?.MessageId ? messageId?.MessageId : messageId}`);
                }
            });
    }

    const finalizeOrder = () => setOptionalPhoneAvailable(true)

    const optionalMobileChangeHandler = (e) => {
        const numberPattern = /^\+?[0-9]+$/g;
        const testPattern = numberPattern.test(e.target.value);
        // console.log(e.target.value, 'check:', testPattern);
        if (testPattern === true) {
            const mobNumber = e.target.value.substring(0, 11);
            setOptionalMobileNumber(mobNumber);
        }
    }

    return (
        <div className='cart-area'>
            {
                !optionalPhoneAvailable ?
                    <>
                        {cart && Object.keys(cart).length > 0 ?
                            <>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>units</th>
                                            <th>item </th>
                                            <th>cost</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(cart).map((item, cartIndex) => {
                                            const itemName = getNameForUuid(item);
                                            return (itemName === null) ? null : <tr key={cartIndex}>
                                                <td>
                                                    {cart[item].count}
                                                </td>
                                                <td>
                                                    <span className='cart-item-name'>{itemName}</span>
                                                </td>
                                                <td>
                                                    {numberWithCommas(getCostForUuid(item, cart[item].count))}
                                                </td>
                                                <td>
                                                    <a className='cart-remove-item' href="!#" onClick={() => deleteItem(item)} ><i className="material-icons">remove_circle</i></a>
                                                </td>
                                            </tr>
                                        })
                                        }
                                        <tr className='total-row'>
                                            <td></td>
                                            <td>Total Cost</td>
                                            <td>{numberWithCommas(totalCost)}</td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br />
                                {
                                    !orderPlaced && !optionalPhoneAvailable &&
                                    < button className='btn-cta' onClick={finalizeOrder} type='button'>finalize order</button>
                                }
                            </>
                            : <><p>Your cart is empty... </p><p>check the product offerings and add items to your cart</p></>
                        }
                    </>
                    : <>
                        <label for="optionalMobile">Optional mobile (for quicker response)</label>
                        <input type="text" id="optionalMobile" onChange={optionalMobileChangeHandler} value={optionalMobileNumber} placeholder='Enter a valid number to get a quicker response...' />
                        <br />
                        {
                            !orderPlaced && optionalPhoneAvailable &&
                            <button className='btn-cta' onClick={placeOrder} type='button'
                            // disabled={optionalMobileNumber.length < 7} 
                            >
                                place order
                            </button>
                        }
                    </>
            }
            <br />
            {orderPlacementMessage && orderPlaced && <p>{orderPlacementMessage}</p>}
        </div >
    )
}

export default Cart;