import React, { useEffect, /* useState */ } from "react";
import { useAuth0 } from "@auth0/auth0-react";
// import axios from 'axios';
import PropTypes from 'prop-types';
import '../styles/common.scss';

const AuthProfile = (props) => {
    const { setAuthenticated, setLoading, setIdToken } = props;

    const useAuth0Object = useAuth0();
    const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0Object;
    const { loginWithRedirect } = useAuth0();
    const { logout } = useAuth0();
    // const [sampleApiCall, setSampleApiCall] = useState();
    // const [showSampleCall, setShowSampleCall] = useState(false);

    // const toggleVisibility = () => {
    //     setShowSampleCall(!showSampleCall);
    // }

    useEffect(() => {
        // console.log('useAuth0Object:', useAuth0Object);

        const getToken = async () => {
            const idToken = await getAccessTokenSilently();
            // console.log('TODO: Profile idToken:', idToken);
            setIdToken(idToken);
        }
        isAuthenticated && getToken();
        setAuthenticated(isAuthenticated);
        setLoading(isLoading);

        // const makeSampleApiCall = async () => {
        //     // console.log('getting API...');
        //     const sampleUrl = `${process.env.REACT_APP_URL}/sample`;
        //     const response = await axios
        //         // .get('https://l1jixho7zh.execute-api.us-east-2.amazonaws.com/getExperience'
        //         .get(sampleUrl)
        //         .catch(err => {
        //             console.error('err:', err);
        //         });
        //     // console.log('response?.data:', response?.data);
        //     setSampleApiCall(response?.data);
        // }

        // makeSampleApiCall();

    }, [useAuth0Object, setAuthenticated, setLoading, setIdToken, user, isAuthenticated, isLoading, getAccessTokenSilently]);

    const logOutHandler = () => {
        logout({ returnTo: window.location.origin });
    }

    if (isLoading) {
        return <p className="loading-text">Loading...</p>
    }

    return (
        <>
            {isAuthenticated ? (
                <div className="profile-section">
                    {/* <img src={user.picture} alt={user.name} /> */}
                    <div className="profile-info">
                        <span className="header-text">Hello {user.nickname} <br />
                            {/* ( {user.name} ) */} </span>
                        <button onClick={logOutHandler} className="btn btn-cta">Log out</button>
                    </div>
                </div>
            ) : (
                <button className="btn btn-cta nav-btn" onClick={() => loginWithRedirect()}>Log In</button>
            )}
            {/* TODO: check connection here... */}
            {/* <button type='button' onClick={toggleVisibility}>check connection</button> */}
            {/* {showSampleCall ?
                <p>checking API availability from: {process.env.REACT_APP_URL} <br />
                    API response: {JSON.stringify(sampleApiCall)} </p> : null} */}

        </>
    );
};

AuthProfile.propTypes = {
    setAuthenticated: PropTypes.func,
    setLoading: PropTypes.func,
    setIdToken: PropTypes.func
};

export default AuthProfile;