import React from "react";
import Cart from './cart';
import AuthProfile from '../sections/auth';
import Accordion from 'react-bootstrap/Accordion';

export const CartDropdown = (props) => {
    const { authenticated, cart, inventory, setAuthenticated, setIdToken, setCart, setLoading } = props;
    return <div className="accordion-wrapper">
        <Accordion className="custom-accordion">
            <Accordion.Item eventKey="0">
                <Accordion.Header><i className="material-icons material-icon-cart" title="cart">shopping_cart</i></Accordion.Header>
                <Accordion.Body>
                    {authenticated && cart ?
                        <Cart cart={cart} inventoryArray={inventory} setCart={setCart} />
                        : <div className="sign-in">
                            <span className="sign-in-blurb">To see your cart...</span>
                            <AuthProfile setAuthenticated={setAuthenticated} setLoading={setLoading}
                                setIdToken={setIdToken} />
                        </div>}
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    </div>
}