import React, { useState, useEffect } from 'react';
import loadingGif from './../images/lewe-brochure.png';
import { isLoadingGifSeenAlready } from './utils';

const LoadingGif = () => {
    const [seenAlready, setSeenAlready] = useState(false);

    useEffect(() => {
        const UPDATE_SESSION_STORAGE = true;
        setSeenAlready(isLoadingGifSeenAlready(UPDATE_SESSION_STORAGE))
    }, []);

    return seenAlready === false ?
        <img src={loadingGif} alt="loading" className='loading-gif' />
        : null
}

export default LoadingGif;