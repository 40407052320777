
import React, { useState, } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { checkAndUpdateCart, numberWithCommas } from '../utils';
import placeholder from '../../images/placeholder.png';

export const InventoryItem = (props) => {
    const useAuth0Object = useAuth0();
    const { user } = useAuth0Object;

    const { inventoryItem, setCart } = props;
    // const categoryDescription = Object.keys(inventoryCategory).find(invC => {
    //     return (inventoryCategory[invC] === inventoryItem.invCategory) ?
    //         invC : 'misc'
    // });
    const [count, setCount] = useState(inventoryItem.invDefaultUnit);
    const setNewCount = (e) => {
        setCount(e.target.value);
    }
    const placeOrder = () => {
        // console.log('Add to cart:', count, ' units of:', inventoryItem.invUuid);
        const currentOrder = {
            count: count,
            price: inventoryItem.invPrice,
            uuid: inventoryItem.invUuid
        };
        checkAndUpdateCart(currentOrder, setCart, user?.email);
    }

    return <div className="card">
        <div className='card-image'>
            <img className="card-img-top" src={inventoryItem.invImageUrl ? inventoryItem.invImageUrl : placeholder} alt="Car Bon Lewe Trading" />
        </div>
        <div className="card-body">
            <h6 className="card-title">{inventoryItem.invCompany}</h6>
            <h5 className="card-title">{inventoryItem.invName}</h5>
            <p className="card-text">{inventoryItem.invDescription}</p>
            <div className="card-cta">
                <div className="card-cost">
                    <p className="card-text-bottom">cost</p>
                    <p className="card-text">{numberWithCommas(inventoryItem.invPrice)}</p>
                </div>
                <div className="card-quantity">
                    <label htmlFor="quantity" className="card-text-bottom">quantity</label>
                    <input id='quantity' type='number' value={count} onChange={setNewCount} /> <br />
                </div>
            </div>
            <button type='button' className="btn btn-cta" onClick={placeOrder}>Add to cart for: {numberWithCommas(count * inventoryItem.invPrice)}</button>
        </div>
    </div>
}